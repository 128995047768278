<template>
  <div>
    <b-container fluid
                 class="part1">
      <b-row>
        <b-col class="col-12 text1 text-center text-lg-left">成为测量大师合伙人</b-col>
        <b-col class="col-12 text2 text-center text-lg-left">我们做的不仅仅是一份工作，更是在为[改善人类居住环境做卓越贡献]</b-col>
        <b-col class="col-12 text2 text-center text-lg-left">加盟电话：0510-85186908</b-col>
      </b-row>
    </b-container>
    <div class="part2">
      <div class="title">什么是测量大师【城市合伙人】</div>
      <div class="info">并不是拥有一定的拓客资源才能成为测量大师的城市合伙人，我们也非常欢迎对家装建材行业有着深入理解，想把想法变成现实，想做实事的行业专家加入，更欢迎拥有IT服务能力、技能培训能力的同行一起加入，帮助行业伙伴，打造核心竞争力，数字化成功转型、帮助成功，实现价值共生，共赢未来。
      </div>
      <b-button variant="primary"
                @click="show">加入我们</b-button>
    </div>
    <div class="part3">
      <div class="title">为什么要成为测量大师城市合伙人</div>
      <div class="content">

        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/partner/icon1.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible1 ? null : 'collapsed'"
                 :aria-expanded="visible1 ? 'true' : 'false'"
                 aria-controls="collapse-1"
                 @click="isPhone?visible1 = !visible1:null">
              <div class="item_title">想法成真</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible1"
                        id="collapse-1">
              <div class="item_content">只要你拥有真实可行的想法，这个想法真的能为行业造福帮助同行成功，测量大师将联合与你一起变成现实，测量大师不仅能给你技术、想法上的支持，拓客、营销、培训、售后等多方面都会给予支持。</div>
            </b-collapse>
          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/partner/icon2.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible2 ? null : 'collapsed'"
                 :aria-expanded="visible2 ? 'true' : 'false'"
                 aria-controls="collapse-2"
                 @click="isPhone?visible2 = !visible2:null">
              <div class="item_title">赋能行业</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible2"
                        id="collapse-2">
              <div class="item_content">新格尔在过去的十多年里已积累3万家工厂，20多万门店，只要想法可行，马上就可看到效果，对行业赋能的过程将变得更加简单。</div>
            </b-collapse>
          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/partner/icon3.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible3 ? null : 'collapsed'"
                 :aria-expanded="visible3 ? 'true' : 'false'"
                 aria-controls="collapse-3"
                 @click="isPhone?visible3 = !visible3:null">
              <div class="item_title">新的机遇</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible3"
                        id="collapse-3">
              <div class="item_content">成为测量大师城市合伙人，将开启新的机遇与机会，不仅能结识更多行业内头部玩家，还能结识更多服务这个产业优质服务商，对自己的事业也将带来偌大的帮助。</div>
            </b-collapse>
          </div>
        </div>
        <div class="item">
          <div class="item_left">
            <b-img :src="require('../../assets/img/partner/icon4.png')"></b-img>
          </div>
          <div class="item_right">
            <div class="item_title_fa"
                 :class="visible4 ? null : 'collapsed'"
                 :aria-expanded="visible4 ? 'true' : 'false'"
                 aria-controls="collapse-4"
                 @click="isPhone?visible4 = !visible4:null">
              <div class="item_title">资源支持</div>
              <b-icon-chevron-up></b-icon-chevron-up>
            </div>
            <b-collapse v-model="visible4"
                        id="collapse-4">
              <div class="item_content">成为测量大师合伙人，不仅能得到测量大师的全力的支持，还能得到新格尔的资源支持，包括IT服务、培训服务、营销服务、产品服务等，让你的事业事半功倍。</div>
            </b-collapse>
          </div>
        </div>

      </div>
    </div>
    <myhead :background="'transparent'"></myhead>
    <foot></foot>
    <right></right>
    <applacation ref="applacation"></applacation>
  </div>
</template>

<script>
import myhead from '@/components/head.vue'
import foot from '@/components/foot.vue'
import right from '@/components/right.vue'
import applacation from '@/components/partner.vue'
export default {
  name: "partner",
  data () {
    return {
      screenWidth: document.body.clientWidth, // 屏幕尺寸
      visible1: false,
      visible2: false,
      visible3: false,
      visible4: false,
    }
  },
  components: {
    foot,
    right,
    myhead,
    applacation
  },
  mounted () {
    this.onResize()
    window.addEventListener("resize", () => {
      this.screenWidth = document.body.clientWidth
      this.onResize()
    })
  },
  destroyed () {
    window.removeEventListener("resize", this.onResize)
  },
  computed: {
    isPhone () {
      if (this.screenWidth < 576) {
        return true;
      } else {
        return false;
      }

    },

  },
  methods: {
    onResize () {
      if (this.screenWidth < 576) {
        this.visible1 = false
        this.visible2 = false
        this.visible3 = false
        this.visible4 = false
        this.visible5 = false
        this.visible6 = false
      } else {
        this.visible1 = true
        this.visible2 = true
        this.visible3 = true
        this.visible4 = true
        this.visible5 = true
        this.visible6 = true
      }
    },
    show () {
      this.$refs.applacation.showModal()
    }
  }
}
</script>

<style lang="less" scoped>
.part1 {
  background: url("../../assets/img/partner/bac.png");
  background-size: cover;
  color: white;

  padding: 6rem 1rem;
  .text1 {
    font-size: 1.5rem;
    font-weight: 200;
    margin-bottom: 0.2rem;
  }
  .text2 {
    font-weight: 200;
    margin-bottom: 0.1rem;
  }
}
.part2 {
  background: rgb(247, 247, 247);
  padding: 3rem 1rem;
  color: #333333;
  .title {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }
  .info {
    width: 90%;
    margin: 0 auto 2rem;
    font-size: 0.8rem;
    text-indent: 1.6rem;
  }
  .btn {
    display: block;
    padding: 0.5rem 2rem;
    font-size: 0.8rem;
    margin: auto;
  }
}
.part3 {
  padding: 1.5rem 0;
  background: rgb(234, 239, 243);
  .title {
    padding: 0;
    font-size: 1.2rem;
    text-align: center;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 90%;
    margin: 2rem auto 0;
    .item {
      background: white;
      display: flex;
      padding: 1rem;
      width: 100%;
      margin-bottom: 1rem;
      .item_left {
        margin-right: 1rem;
        img {
          width: 3rem;
          height: 3rem;
        }
      }
      .item_right {
        width: 100%;
        .item_title_fa {
          outline: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          position: relative;
          &.collapsed /deep/ .b-icon {
            transform: rotate(180deg);
          }
          /deep/ .b-icon {
            display: block;
            height: 16px;
            width: 16px;
            position: absolute;
            right: 10px;
            top: auto;
            transition: transform 0.2s ease-out;
          }
        }
        .item_title {
          font-size: 1.1rem;
          margin-bottom: 0.8rem;
          padding-top: 0.5rem;
        }
        .item_content {
          color: #999999;
          font-size: 0.8rem;
        }
        .btn {
          font-size: 0.8rem;
        }
      }
    }
  }
}
@media screen and (min-width: 576px) {
  .part1 {
    background: url("../../assets/img/partner/bac.png");
    background-size: cover;
    color: white;
    padding: 8rem 10rem;
    .text1 {
      font-size: 1.6rem;
      font-weight: 200;
      margin-bottom: 0.5rem;
    }
    .text2 {
      font-weight: 200;
      margin-bottom: 0.1rem;
    }
  }
  .part2 {
    background: rgb(247, 247, 247);
    padding: 3rem 10rem;
    color: black;
    .title {
      text-align: center;
      font-size: 2rem;
      margin-bottom: 1rem;
    }
    .info {
      width: 80%;
      font-size: 1rem;
      margin: 0 auto 2rem;
    }
    .btn {
      display: block;
      padding: 0.7rem 3rem;
      font-size: 0.9rem;
      margin: auto;
    }
  }
  .part3 {
    padding: 3.5rem 10rem 1.5rem;
    background: rgb(234, 239, 243);
    .title {
      padding: 0 0 2rem;
      font-size: 2rem;
      text-align: center;
    }
    .content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      margin-top: 0rem;
      .item {
        background: white;
        display: flex;
        padding: 2rem 3rem 2rem 3rem;
        width: calc(50% - 0.5rem);
        margin-bottom: 1rem;
        .item_left {
          margin-right: 1rem;
          img {
            width: 60px;
            height: 60px;
          }
        }
        .item_right {
          width: 100%;
          .item_title_fa {
            /deep/ .b-icon {
              display: none;
            }
          }
          .item_title {
            font-size: 1.2rem;
            margin-bottom: 0.8rem;
            padding-top: 0;
          }
          .item_content {
            color: #999999;
            font-size: 1rem;
          }
          .btn {
            font-size: 0.8rem;
          }
        }
      }
    }
  }
}
</style>
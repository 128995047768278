<template>
  <div>
    <b-modal ref="my-modal"
             title="申请成为合伙人">
      <b-form @submit.stop.prevent="submit">
        <b-form-group label="姓名"
                      label-cols="3"
                      label-cols-sm="2">
          <b-form-input v-model="$v.form.name.$model"
                        :state="validateState('name')"
                        aria-describedby="input-live-feedback"
                        placeholder="怎么称呼您"
                        trim></b-form-input>
          <b-form-invalid-feedback id="input-live-feedback">
            姓名不能为空
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="手机"
                      label-cols="3"
                      label-cols-sm="2">
          <b-form-input v-model="$v.form.tel.$model"
                        :state="validateState('tel')"
                        aria-describedby="input-2-live-feedback"
                        placeholder="输入您的手机号"
                        trim></b-form-input>
          <b-form-invalid-feedback id="input-2-live-feedback">
            请输入正确的手机号
          </b-form-invalid-feedback>
          <div v-show="show"
               @click="getcode"
               class="getcode">获取</div>
          <div v-show="!show"
               class="count">{{count}} s</div>
        </b-form-group>
        <b-form-group label="验证码"
                      label-cols="3"
                      label-cols-sm="2">
          <b-form-input v-model="$v.form.code.$model"
                        :state="validateState('code')"
                        aria-describedby="input-3-live-feedback"
                        placeholder="输入验证码"
                        trim></b-form-input>
          <b-form-invalid-feedback id="input-3-live-feedback">
            验证码不能为空
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label="我能提供"
                      label-cols="3"
                      label-cols-sm="2">
          <b-form-select v-model="$v.form.provide.$model"
                         :options="options"
                         :state="validateState('provide')"
                         value-field="id"
                         text-field="name"
                         aria-describedby="input-4-live-feedback">

          </b-form-select>
          <b-form-invalid-feedback id="input-4-live-feedback">
            选项不能为空
          </b-form-invalid-feedback>
        </b-form-group>
        <b-form-group label=""
                      label-cols="3"
                      label-cols-sm="2">
          <b-form-textarea v-model="$v.form.detail.$model"
                           :state="validateState('detail')"
                           aria-describedby="input-5-live-feedback"
                           placeholder="详细描述"
                           rows="3"
                           trim></b-form-textarea>
          <b-form-invalid-feedback id="input-5-live-feedback">
            详细描述不能为空
          </b-form-invalid-feedback>
        </b-form-group>
      </b-form>
      <template slot="modal-footer">
        <div class="footer">
          <b-button variant="primary"
                    @click="submit">提交</b-button>
          <b-button variant="outline-primary"
                    @click="hideModal">取消</b-button>
        </div>
      </template>
    </b-modal>
  </div>
</template>

<script>
// https://www.vuelidate.cn/#sub-package-content 使用的Vuelidate用户表单验证
import { validationMixin } from "vuelidate";
import { required } from 'vuelidate/lib/validators'
const myTel = (value) => {
  var telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
  return telStr.test(value)
}
export default {
  name: "applation",
  mixins: [validationMixin],
  data () {

    return {
      form: {
        name: "",
        tel: "",
        code: "",
        provide: null,
        detail: ""
      },
      options: [
        { id: null, name: "请选择" },
      ],
      show: true,
      count: ""
    }
  },
  computed: {

  },
  validations: {
    form: {
      name: {
        required
      },
      tel: {
        required,
        myTel
      },
      code: {
        required
      },
      provide: {
        required
      },
      detail: {

      }
    }
  },
  mounted () {
    this.offer()
  },
  methods: {
    validateState (name) {
      const { $dirty, $error } = this.$v.form[name];
      return $dirty ? !$error : null;
    },
    showModal () {
      this.$refs['my-modal'].show()
    },
    hideModal () {
      this.$refs['my-modal'].hide()
    },
    submit () {
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      let params = {
        name: this.form.name,
        phone: this.form.tel,
        content: this.form.detail,
        code: this.form.code,
        offer_id: this.form.provide
      }
      this.$axios.post(`${this.$Tools.Conts.domain}official/partners`, params).then(res => {
        if (res.data.code == 0) {
          this.$toast.success({
            message: res.data.message,
            timeOut: 2000
          })
          this.hideModal()
        } else {
          this.$toast.warn({
            message: res.data.message,
            timeOut: 2000
          })
        }
      })
    },
    getcode () {
      var telStr = /^[1](([3][0-9])|([4][5-9])|([5][0-3,5-9])|([6][5,6])|([7][0-8])|([8][0-9])|([9][1,8,9]))[0-9]{8}$/;
      if (!telStr.test(this.form.tel)) {
        this.$toast.warn({
          message: "请先填写正确的手机号",
          timeOut: 2000
        })
        return false;
      }
      const TIME_COUNT = 60;
      if (!this.timer) {
        this.count = TIME_COUNT;
        this.show = false;
        this.timer = setInterval(() => {
          if (this.count > 0 && this.count <= TIME_COUNT) {
            this.count--;
          } else {
            this.show = true;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }

      this.$axios.get(`${this.$Tools.Conts.domain}verify-code/${this.form.tel}?type=17`).then(res => {
        if (res.data.code == 0) {
          this.$toast.success({
            message: '验证码发送成功',
            timeOut: 2000
          })
        }
      })
    },
    offer () {
      this.$axios.get(`${this.$Tools.Conts.domain}official/offers`).then(res => {
        if (res.data.code == 0) {
          this.options = this.options.concat(res.data.data.reverse())
        }
      })

    }

  },

}
</script>

<style lang="less" scoped>
.footer {
  .btn {
    padding: 0.4rem 2.2rem;
    &:first-child {
      margin-right: 1rem;
    }
  }
}
form {
  /deep/ .form-row {
    .col-form-label {
      font-size: 0.9rem;
      text-align: right;
    }
    input,
    select,
    textarea {
      font-size: 0.9rem;
      &::placeholder {
        color: #999999;
      }
    }
    .getcode {
      width: 50px;
      font-size: 15px;
      color: rgba(45, 140, 240, 1);
      position: absolute;
      right: 15px;
      top: 6px;
      cursor: pointer;
    }
    .count {
      width: 50px;
      font-size: 15px;
      color: red;
      position: absolute;
      right: 15px;
      top: 6px;
    }
  }
}
</style>